import React from 'react';
import {Project} from "./Project";
import {Link} from "react-router-dom";


interface ProjectCardProps {
    project: Project;
    onEdit: (project: Project) => void;
}

function ProjectCard({project, onEdit}: Readonly<ProjectCardProps>) {
    const handleEditClick = (projectBeingEdited: Project) => {
        onEdit(projectBeingEdited);
    };
    return (
        <div className="row">
            <div key={project.id} className="cols-sm">
                <div className="card">
                    <img src={project.imageUrl} alt={project.name}/>
                    <section className="section dark">
                        <Link to={'/projects/' + project.id}>
                            <h5 className="strong">
                                <strong>{project.name}</strong>
                            </h5>
                            <p>{formatDescription(project.description)}</p>
                            <p>Budget : {project.budget.toLocaleString()}</p>
                        </Link>
                        <button className="bordered"
                                onClick={() => {
                                    handleEditClick(project);
                                }}
                        >
                            <span className="icon-edit "></span>

                        </button>
                    </section>
                </div>
            </div>
        </div>
    );
}

function formatDescription(description: string): string {
    return description.substring(0, 60) + '...';
}

export default ProjectCard;